import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { ReactComponent as SettingIcon } from '../../assets/images/setting.svg';
import { ReactComponent as SituationIcon } from '../../assets/images/situation.svg';
import { ReactComponent as PointIcon } from '../../assets/images/point.svg';
import { ReactComponent as BoosterIcon } from '../../assets/images/booster.svg';
import { ReactComponent as MissioniIcon } from '../../assets/images/missioni.svg';
import { ReactComponent as OrdiniIcon } from '../../assets/images/ordine.svg';
import { ReactComponent as Gallery } from '../../assets/images/gallery.svg';
import { ReactComponent as PLAY } from '../../assets/images/play.svg';
import { VscTypeHierarchy } from 'react-icons/vsc';
import styled from 'styled-components';
import { Box, MaskImg, Text, Wrapper } from '../../components/Ui';
import Situation from '../../components/situation';
import Missioni from '../../components/missioni';
import Points from '../../components/points';
import Ordini from '../../components/ordini';
import { useSelector } from 'react-redux';
import Meccanica from '../../components/Meccanica';
import ListaPremi from '../../components/ListaPremi/ListaPremi';
import UserGallery from '../UserGallery';
import Plays from '../../components/Plays/Plays';
import Hierarchy from '../Hierarchy/HierarchyPage';
const Container = styled.div``;
const Content = styled.div`
  margin: 20px 0;
`;
const Nav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 25px;
`;
const NavOption = styled(NavLink)`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colore_bordo_box};
  //border: 1px solid ${({ theme }) => theme.borderCode};
  border-radius: ${({ theme }) => theme.border_radius_generale_piccolo};
  padding: 10px;
  &.activeLink {
    span {
      display: block;
      color: ${props => props.theme.primary};
    }
  }
  span {
    margin-left: 10px;
    display: none;
  }
  .icon {
    background-color: ${props => props.theme.primary};
  }
  svg {
    width: 50px;
    height: 60px;
    color: ${props => props.theme.primary};
    fill: ${props => props.theme.primary};
  }
`;
function Options() {
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});
  const individual_agreement = useSelector(state => state.app.config.individual_agreement);
  const { is_vdr } = useSelector(state => state.user.user || {});
  const { singola_pattuizione_gerarchia } = useSelector(state => state?.user?.user || {});
  const data = singola_pattuizione_gerarchia
    ? Array.isArray(singola_pattuizione_gerarchia)
      ? singola_pattuizione_gerarchia
      : [singola_pattuizione_gerarchia]
    : [];

  return (
    <Wrapper>
      <Nav>
        {option?.menuSettings?.settings?.visibility === 'show' && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/setting'}>
            {option?.menuSettings?.settings?.icon_url ? (
              <MaskImg src={option?.menuSettings?.settings?.icon_url} className='icon' />
            ) : (
              <SettingIcon />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.settings?.label}
            </Text>
          </NavOption>
        )}
        {option?.menuSettings?.situation?.visibility === 'show' && data?.length === 0 && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/situation'}>
            {option?.menuSettings?.situation?.icon_url ? (
              <MaskImg src={option?.menuSettings?.situation?.icon_url} className='icon' />
            ) : (
              <SituationIcon />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.situation?.label}
            </Text>
          </NavOption>
        )}
        {option?.menuSettings?.points?.visibility === 'show' && data?.length === 0 && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/point'}>
            {option?.menuSettings?.points?.icon_url ? (
              <MaskImg src={option?.menuSettings?.points?.icon_url} className='icon' />
            ) : (
              <PointIcon />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.points?.label}
            </Text>
          </NavOption>
        )}
        {option?.menuSettings?.booster?.visibility === 'show' && data?.length === 0 && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/booster'}>
            {option?.menuSettings?.booster?.icon_url ? (
              <MaskImg src={option?.menuSettings?.booster?.icon_url} className='icon' />
            ) : (
              <BoosterIcon />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.booster?.label}
            </Text>
          </NavOption>
        )}
        {option?.menuSettings?.missions?.visibility === 'show' && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/missioni'}>
            {option?.menuSettings?.missions?.icon_url ? (
              <MaskImg src={option?.menuSettings?.missions?.icon_url} className='icon' />
            ) : (
              <MissioniIcon />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.missions?.label}
            </Text>
          </NavOption>
        )}
        {option?.menuSettings?.orders?.visibility === 'show' && data?.length === 0 && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/ordini'}>
            {option?.menuSettings?.orders?.icon_url ? (
              <MaskImg src={option?.menuSettings?.orders?.icon_url} className='icon' />
            ) : (
              <OrdiniIcon />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.orders?.label}
            </Text>
          </NavOption>
        )}
        {option?.menuSettings?.prizes?.visibility === 'show' && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/premi'}>
            {option?.menuSettings?.prizes?.icon_url ? (
              <MaskImg src={option?.menuSettings?.prizes?.icon_url} className='icon' />
            ) : (
              <BoosterIcon />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.prizes?.label}
            </Text>
          </NavOption>
        )}
        {option?.menuSettings?.photogallery?.visibility === 'show' && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/photogallery'}>
            {option?.menuSettings?.photogallery?.icon_url ? (
              <MaskImg src={option?.menuSettings?.photogallery?.icon_url} className='icon' />
            ) : (
              <Gallery />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.photogallery?.label}
            </Text>
          </NavOption>
        )}

        {option?.menuSettings?.hierarchy?.visibility === 'show' &&
          individual_agreement &&
          data.length > 0 &&
          !Boolean(is_vdr) && (
            <NavOption activeClassName='activeLink' exact to={'/user/options/hierarchy'}>
              {option?.menuSettings?.hierarchy?.icon_url ? (
                <MaskImg src={option?.menuSettings?.hierarchy?.icon_url} className='icon' />
              ) : (
                <VscTypeHierarchy />
              )}
              <Text as='span' bold size={18} margin='0 0 0 10px'>
                {option?.menuSettings?.hierarchy?.label}
              </Text>
            </NavOption>
          )}
        {option?.menuSettings?.plays?.visibility === 'show' && (
          <NavOption activeClassName='activeLink' exact to={'/user/options/plays'}>
            {option?.menuSettings?.plays?.icon_url ? (
              <MaskImg src={option?.menuSettings?.plays?.icon_url} className='icon' />
            ) : (
              <PLAY />
            )}
            <Text as='span' bold size={18} margin='0 0 0 10px'>
              {option?.menuSettings?.plays?.label}
            </Text>
          </NavOption>
        )}
      </Nav>
      <Content>
        {data?.length === 0 && (
          <>
            <Route path='/user/options/situation'>
              <Situation />
            </Route>

            <Route path='/user/options/point'>
              <Points />
            </Route>
            <Route path='/user/options/ordini'>
              <Ordini />
            </Route>
          </>
        )}
        <Route path='/user/options/setting'>
          <Meccanica />
        </Route>
        <Route path='/user/options/missioni'>
          <Missioni />
        </Route>
        <Route path='/user/options/premi'>
          <ListaPremi />
        </Route>
        <Route path='/user/options/photogallery'>
          <UserGallery />
        </Route>
        <Route path='/user/options/plays'>
          <Plays />
        </Route>
        {!Boolean(is_vdr) && data?.length > 0 && (
          <Route path='/user/options/hierarchy'>
            <Hierarchy />
          </Route>
        )}
      </Content>
    </Wrapper>
  );
}

export default Options;
