import React from 'react';
import { Button } from '../Ui';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ hierarchicalData }) => {
  const exportToExcel = () => {
    const rows = exportToExcelTable(hierarchicalData);
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'singola_pattuizione_gerarchia.xlsx');
  };

  return (
    <Button active onClick={exportToExcel} className='mt-20'>
      Export
    </Button>
  );
};

export default ExportToExcel;

function getMaxDepth(data, depth = 0) {
  let maxDepth = depth;
  for (const item of data) {
    if (item.children && item.children.length > 0) {
      maxDepth = Math.max(maxDepth, getMaxDepth(item.children, depth + 1));
    }
  }
  return maxDepth;
}

function exportToExcelTable(data) {
  const rows = [];
  const dynamicLabels = [];
  const maxDepth = getMaxDepth(data);

  const processData = nodes => {
    nodes.forEach(node => {
      if (node.children) {
        processData(node.children);
      } else if (node.details?.sections) {
        node.details.sections.forEach(section => {
          section.rows.forEach(row => {
            if (!dynamicLabels.includes(row.label)) {
              dynamicLabels.push(row.label);
            }
          });
        });
      }
    });
  };

  processData(data);

  const headerRow =
    maxDepth === 1
      ? [
          // 'Filiale',
          // 'Agente',
          'Partita iva',
          'Ragione Sociale',
          'Email',
          'Nome',
          'Cognome',
          'Iscritto',
          'Singola Pattuizione',
          ...dynamicLabels,
        ]
      : [
          'Area commerciale',
          'Filiale',
          'Agente',
          'Partita iva',
          'Ragione Sociale',
          'Email',
          'Nome',
          'Cognome',
          'Iscritto',
          'Singola Pattuizione',
          ...dynamicLabels,
        ];

  rows.push(headerRow);

  const populateRows = (nodes, area = '', filiale = '') => {
    nodes.forEach(node => {
      if (node.children) {
        populateRows(node.children, area || '-', filiale || '-');
      } else {
        const rowData =
          maxDepth === 1
            ? [
                // filiale || '-',
                // node.label || '-',
                node.vatcode || '-',
                node.ragione_sociale || '-',
                node.details?.email || '-',
                node.details?.firstname || '-',
                node.details?.lastname || '-',
                node.details?.registered ? 'SI' : 'NO',
                node.details?.individual_agreement_accepted ? 'SI' : 'NO',
                ...Array(dynamicLabels.length).fill('-'),
              ]
            : [
                area || '-',
                filiale || '-',
                node.label || '-',
                node.vatcode || '-',
                node.ragione_sociale || '-',
                node.details?.email || '-',
                node.details?.firstname || '-',
                node.details?.lastname || '-',
                node.details?.registered ? 'SI' : 'NO',
                node.details?.individual_agreement_accepted ? 'SI' : 'NO',
                ...Array(dynamicLabels.length).fill('-'),
              ];

        if (node.details?.sections) {
          node.details.sections.forEach(section => {
            section.rows.forEach(row => {
              const index = dynamicLabels.indexOf(row.label);
              if (index !== -1) {
                rowData[maxDepth === 1 ? 7 + index : 10 + index] = row.value;
              }
            });
          });
        }

        rows.push(rowData);
      }
    });
  };

  data.forEach(area => {
    if (maxDepth === 1) {
      populateRows(area.children, '', area.label);
    } else if (area.children) {
      area.children.forEach(filiale => {
        if (filiale.children) {
          filiale.children.forEach(agente => {
            populateRows(
              [agente],
              maxDepth < 3 ? area.label : filiale.label,
              maxDepth < 3 ? filiale.label : agente.label,
            );
          });
        }
      });
    }
  });

  return rows;
}
