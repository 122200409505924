import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import useQuery from '../hooks/useQuery';

import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';

import AuthLayout from '../layouts/AuthLayout';
import BaseLayout from '../layouts/BaseLayout';

import Home from '../views/Home';
import NotFound from '../views/NotFound';
import Catalog from '../views/Catalog/Catalog';
import Product from '../views/Product/Product';
import Wishlist from '../views/Wishlist/Wishlist';
import Cart from '../views/Cart/Cart';

import Options from '../views/Options/Options';
import AuthTemplate from '../templates/AuthTemplate';
import Notification from '../views/Notification';
import NotificationDetail from '../views/NotificationDetail';
import Checkout from '../views/Checkout';
import OrderComplited from '../views/OrderCompleted';
import ExternalLogin from '../views/ExternalLogin/ExternalLogin';
import FakeLogin from '../views/FakeLogin/FakeLogin';
import { signinSuccess } from '../store/actions/auth';
import Gallery from '../views/Gallery/Gallery';

import { appQuery } from '../store/actions/app';
import RedirectView from '../views/RedirectView/RedirectView';
import ExternalSigninUp from '../views/ExternalSigninUp/ExternalSigninUp';
import Premi from '../views/Premi';
import IndividualAgreement from '../views/IndividualAgreement/IndividualAgreement';
import ValidateEmailApp from '../views/ValidateEmailApp/ValidateEmailApp';
import HomePoints from '../views/HomePoints';

const Register = React.lazy(() => import('../views/Register'));
const Game = React.lazy(() => import('../views/Game'));
const Initiative = React.lazy(() => import('../views/Initiative'));
const Login = React.lazy(() => import('../views/Login'));
const RecoveryPassword = React.lazy(() => import('../views/RecoveryPassword'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));
const Profile = React.lazy(() => import('../views/Profile'));

const UserActivated = React.lazy(() => import('../views/UserActivated'));
const Awards = React.lazy(() => import('../views/Awards'));
const Contact = React.lazy(() => import('../views/Contact/Contact'));

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const queryApp = query.get('app');
  const queryToken = query.get('token');

  useEffect(() => {
    const localQueryApp = localStorage.getItem('queryApp');

    if (queryApp || localQueryApp) {
      queryToken && localStorage.setItem('token', queryToken);
      localStorage.setItem('queryApp', true);
      queryToken && dispatch(signinSuccess(queryToken));
      if (queryApp) {
        dispatch(appQuery(queryApp));
      } else {
        dispatch(appQuery(localQueryApp));
      }
    } else {
      dispatch(appQuery(false));
    }
  }, [queryApp, queryToken]);

  return (
    <Route
      {...rest}
      render={props =>
        rest.isLogged !== null || queryToken ? ( // ||queryApp
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          <Redirect
            to={{
              pathname: !rest.ownLogin ? '/' : '/auth/login',
              state: {
                from: rest.location.pathname,
                isLogged: rest.isLogged,
              },
            }}
          />
        )
      }
    />
  );
};

const Routes = props => {
  const { ownLogin, multipromo } = props;
  const dispatch = useDispatch();
  const appName = useSelector(state => state.app.config.code);
  const hp_new = useSelector(state => state.app.config.hp_new);
  const individual_agreement_accepted = useSelector(
    state => state.user?.user?.userinfo?.individual_agreement_accepted,
  );
  let ownRoutes = null;
  dispatch(appQuery(false));
  if (ownLogin) {
    ownRoutes = (
      <React.Fragment>
        <Route path='/auth/externalsignin/:token' exact component={ExternalLogin} />
        <Route path='/auth/externalsigninup/:token' exact component={ExternalSigninUp} />
        <Route path='/impersonate/:token' exact component={FakeLogin} />
        <Route path='/auth/register/:token*' exact component={AuthTemplate} />
        <Route path='/auth/login' exact component={AuthTemplate} />
        <Route path='/auth/recovery-password' exact component={AuthTemplate} />
        <Route path='/validate-email-app' exact component={ValidateEmailApp} />
        <Route path='/activate/:id' exact component={UserActivated} />

        {appName && appName === 'CENTROGAMMAGO' && (
          <Route
            path='/centrogammaapp'
            exact
            render={route => <RedirectView {...route} type='centrogammago' />}
          />
        )}

        <Route
          path='/reset-password/:onetimetoken'
          exact
          render={route => <AuthLayout cmp={ResetPassword} {...route} />}
        />
      </React.Fragment>
    );
  }

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <PrivateRoute
            path='/promo/:initiative'
            exact
            component={Initiative}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />

          <PrivateRoute
            path='/'
            exact
            //component={Home}
            component={hp_new ? HomePoints : multipromo ? Home : Initiative}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/single-agreement'
            exact
            component={IndividualAgreement}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/promo/:initiative/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />
          <PrivateRoute
            path='/promo/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />
          <PrivateRoute
            path='/promo/:slug/game/:id/gallery'
            exact
            layoutCmp={BaseLayout}
            isLogged={true}
            component={Gallery}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/premi'
            exact
            layoutCmp={BaseLayout}
            isLogged={true}
            component={Premi}
            ownLogin={ownLogin}
            footer_brand_bar
          />
          <PrivateRoute
            path='/user/profile'
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/user/options/:name'
            exact
            component={Options}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/contact'
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/user/awards'
            exact
            component={Awards}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/catalog'
            exact
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            component={Catalog}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/prodotto/:id'
            exact
            component={Product}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/wishlist'
            exact
            component={Wishlist}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/cart'
            exact
            component={Cart}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/checkout'
            exact
            component={Checkout}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/order_completed'
            exact
            component={OrderComplited}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/notifications'
            exact
            component={Notification}
            layoutCmp={BaseLayout}
            isLogged={true}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/notifications/:id'
            exact
            component={NotificationDetail}
            layoutCmp={BaseLayout}
            isLogged={true}
            ownLogin={ownLogin}
          />

          {ownRoutes}

          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin?.active,
    multipromo: state.app.config.settings.multipromo,
  };
};

export default connect(mapStateToProps)(Routes);
