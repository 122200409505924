import React from 'react';
import { Typography } from '../../Ui';
import { useTranslation } from 'react-i18next';

const RegisterFormSuccess = () => {
  const [t] = useTranslation();

  return (
    <>
      <Typography as='p' align='center' secondary type='bbody'>
        {t('auth.registerformsuccess')}
      </Typography>
      <br />
      <Typography as='p' align='center' secondary type='bbody'>
        {t('auth.registerformsuccessspamemail')}
      </Typography>
    </>
  );
};

export default RegisterFormSuccess;
