import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Routes from './Routes/Routes';
import Favicon from './components/Favicon';
import GenericScripts from './components/GenericScripts';
import GlobalFont from './components/GlobalFont';
import GlobalStyle from './components/GlobalStyle';
import GoogleAnalytics from './components/GoogleAnalytics';
import Seo from './components/Seo/Seo';
import { Loader } from './components/Ui';
import * as actionCreators from './store/actions';
import { getAllNews } from './store/actions/news';
import { configTheme, theme } from './theme/theme';
import { getAlias } from './utils/utils';
import NotFound from './views/NotFound';

function App(props) {
  const dispatch = useDispatch();
  const {
    getUserInfo,
    isLogged,
    appConfig,
    appConfig: { lang, analytics, favicon },
    appError,
    errorOauthSendoToken,
    bootApp,
    loading,
    getGames,
    otherLoginLoading,
  } = props;

  const alias = getAlias();
  const [googleAnalytics, setGoogleAnalytics] = useState(null);
  const [fav, setFav] = useState(null);
  const [fontSource, setFontSource] = useState(null);
  const [showGdpr, setShowGdpr] = useState(false);

  const [t, i18] = useTranslation();
  const queryApp = useSelector(state => state.app.appQuery);
  const seo = useSelector(state => state.app?.config?.i18l?.seo);

  configTheme({
    design: {
      template_props: {
        ...appConfig?.design?.template_props,
      },
    },
  });

  useEffect(() => {
    bootApp();
  }, []);

  useEffect(() => {
    setGoogleAnalytics(analytics);
  }, [analytics]);

  useEffect(() => {
    setFav(favicon);
  }, [favicon]);

  useEffect(() => {
    i18.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (isLogged) {
      getUserInfo();
    }
    dispatch(getAllNews());
  }, [isLogged]);

  useEffect(() => {
    if (appConfig) {
      if (appConfig.design) {
        if (appConfig.design.template_props.font_source) {
          setFontSource(appConfig.design.template_props.font_source);
        }
      }
      if (appConfig.i18l) {
        setShowGdpr(true);
      }
    }
  }, [appConfig]);

  let err = null;
  let errType = null;

  if (appError || errorOauthSendoToken) {
    if (appError) {
      err = appError;
      errType = 'app';
    }
    if (errorOauthSendoToken) {
      err = errorOauthSendoToken;
      errType = 'auth';
    }
  }

  const stringToHTML = function (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    var script = [...doc.querySelectorAll('script')];

    var scripts = script.map(item => {
      let attributs = item.getAttributeNames().reduce((at, current) => {
        at[current] = item.getAttribute(current);
        return at;
      }, {});
      return <script {...attributs}>{item.textContent}</script>;
    });

    return scripts;
  };

  return (
    <React.Fragment>
      <GlobalFont font_source={fontSource} />
      <GoogleAnalytics ga={googleAnalytics} />
      <Favicon favicon={fav} />

      <Seo title={seo?.title} description={seo?.description} />

      {loading || otherLoginLoading ? (
        <Loader initial show={loading} />
      ) : err ? (
        <NotFound type={errType} error={err} />
      ) : (
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader show={true} full />}>
            <React.Fragment>
              <GlobalStyle />
              <Routes />
              {!queryApp && (
                <GenericScripts scripts={stringToHTML(appConfig?.i18l?.gdpr?.content)} />
              )}
            </React.Fragment>
          </Suspense>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    appError: state.app.errorApp,
    appConfig: state.app.config,
    loadingOauthSendToken: state.auth.loadingOauthSendToken,
    errorOauthSendoToken: state.auth.errorOauthSendoToken,
    loading: state.bootApp.loading,
    // otherLoginLoading: state.error.otherLoginLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
    getGames: (slug, isLogged) => dispatch(actionCreators.getGames(slug, isLogged)),
    bootApp: () => dispatch(actionCreators.bootApp()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
