import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button, Col, Flex, Text } from '../Ui';
import { ReactComponent as PointIcon } from '../../assets/images/pointIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import ReactShadowScroll from 'react-shadow-scroll';
import moment from 'moment';
import { numberWithDots } from '../../utils/utils';
import { respondTo } from '../../theme/mixin';
import { getUserInfo } from '../../store/actions';
import { IoIosArrowDown, IoMdDownload } from 'react-icons/io';
import { BsQuestionCircle } from 'react-icons/bs';
import { Kaggle } from 'styled-icons/fa-brands';
import { Handshake } from 'styled-icons/fa-regular';
import * as XLSX from 'xlsx';

const CustomBox = styled(Box)`
  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.colore_bordo_box};
  }
`;
const CustomCol = styled(Col)`
  svg {
    color: ${({ theme }) => theme.primary};
  }
`;
const CustomFlexHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colore_bordo_box};
  display: none;
  ${respondTo.sm`
  display: flex;
     `};
`;
const CustomColPoint = styled(Col)`
  width: 25%;
  ${respondTo.sm`
  width: 20%;
     `};
`;
const CustomText = styled(Text)`
  display: block;
  ${respondTo.sm`
  display: none;
     `};
`;
const Details = styled.details`
  padding: 15px;

  summary {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    font-weight: bold;

    margin-bottom: 5px;
  }
`;
const DownloadButton = styled(Button)`
  margin-top: 20px;
  background-color: #252525;
  color: #fff;
  border: none;

  svg {
    color: #a09f9f;
  }
  a {
    display: flex;
    align-items: center;
  }
  &:hover {
    background-color: #181818;
  }
`;

// First, add this styled component near the other styled components
// Add this import at the top

// Update the TooltipContainer styling
const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  .tooltip-text {
    visibility: hidden;
    width: 280px;
    background-color: white;
    color: #333;
    text-align: left;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 100;
    font-size: 14px;
    opacity: 0;
    transition: all 0.2s ease;
    font-weight: normal;
    left: 80%;
    transform: translateX(-50%);
    top: -120%;
    width: 250px;

    ${respondTo.sm`
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      width: 280px;
    `}
  }

  .icon:hover + .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .icon {
    color: #707070;
    cursor: help;
  }
`;

const Punti = ({ text, num }) => {
  const appName = useSelector(state => state.app.config.code);
  const { actionsPerCategory } = useSelector(state => state.user.user || {});
  const pointsLabel = useSelector(state => state.app.config.points_label);
  const renderText = () => {
    return (
      <Text size={20} text_box>
        {text}
      </Text>
    );
  };

  return text === 'Punti maturati' ? (
    <Details val={num}>
      <summary>
        <Text size={20} text_box>
          {pointsLabel} maturati
        </Text>
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </summary>

      {actionsPerCategory &&
        Object.keys(actionsPerCategory)?.map(item => (
          <Flex justify='space-between' align='center' wrap='nowrap' className='mb-10'>
            <Text size={20} text_box>
              {item}
            </Text>
            <Text size={20} text_box>
              {actionsPerCategory[item].total}
            </Text>
          </Flex>
        ))}
    </Details>
  ) : (
    <Flex justify='space-between' align='center' wrap='nowrap'>
      <Col width={50}>{renderText()}</Col>
      <Col width={50}>
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </Col>
    </Flex>
  );
};
const Coin = ({ text, num, category, date }) => (
  <>
    <Flex align='center' wrap='nowrap'>
      <Col width={80}>
        <Flex gap={30}>
          <Col width={50} padding={5}>
            <Flex wrap='nowrap' gap={5}>
              <CustomText bold capit text_box>
                Data:
              </CustomText>
              <Text size={18} text_box style={{ whiteSpace: 'nowrap' }}>
                {moment(date).format('DD/MM/YYYY HH:MM')}
              </Text>
            </Flex>
          </Col>
          <Col width={50} padding={5}>
            <Text size={18} text_box>
              {category}
            </Text>
          </Col>
        </Flex>
      </Col>
      <CustomColPoint width={20}>
        <Text size={18} upper type='text' text_box align='right'>
          {numberWithDots(num)}
        </Text>
      </CustomColPoint>
    </Flex>
  </>
);

const Points = () => {
  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const {
    userinfo: { score, score_spent, score_gained, score_pending } = {},
    actions,
    base_threshold_enabled,
    base_threshold_description,
    base_threshold_percent,
    targets,
    actionsPerCategory,
  } = useSelector(state => state.user.user || {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  // Remove the columns constant since we won't need it with xlsx
  const appName = useSelector(state => state.app.config.code);
  const pointsLabel = useSelector(state => state.app.config.points_label);
  const pointsNote = useSelector(state => state.app.config.points_note);
  const exportToExcel = () => {
    const dataToExport = actions.map(item => ({
      Id: item.id,
      Categoria: item.category,
      Dettaglio: item.details,
      Punti: item.points,
      'Data creazione': item.created_at,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Actions');
    XLSX.writeFile(workbook, 'Dettaglio.xlsx');
  };
  // const columns = [
  //   <ExcelColumn value='id' label='Id' />,
  //   <ExcelColumn value='category' label='categoria' />,
  //   <ExcelColumn value='details' label='Dettaglio' />,
  //   <ExcelColumn value='points' label='Punti' />,
  //   <ExcelColumn value='created_at' label='Data creazione' />,
  // ];
  return (
    <>
      {base_threshold_enabled && (
        <Box>
          <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
            Soglia base {base_threshold_description}
          </Text>
          <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
            Hai raggiunto il
          </Text>
          <Text align='center' as='h3' bold type='title' primary className='mb-10' text_box>
            {base_threshold_percent}
          </Text>
          <Text align='center' as='h3' bold type='mediumTitle' text_box>
            della soglia base
          </Text>
        </Box>
      )}
      {targets?.length > 0 && (
        <Box style={{ marginTop: '15px' }}>
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '180px' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {targets?.map(item => (
              <Text primary capit className='mb-10' size={20} style={{ paddingLeft: '15px' }}>
                Obiettivo {item.month}: {item.target}
              </Text>
            ))}
          </ReactShadowScroll>
        </Box>
      )}

      <div>
        <CustomBox margin='15px 0 0 0'>
          <Punti
            text={`${pointsLabel} maturati`}
            num={score_gained}
            actionsPerCategory={actionsPerCategory}
          />
          <Punti text={`${pointsLabel} spesi`} num={score_spent} />
          <Punti text={`${pointsLabel} disponibili`} num={score} />

          {/* {appName && appName === 'WURTH' ? ( */}
          <Punti text={`Punti da confermare*`} num={score_pending} />
          {/* ) : null} */}
        </CustomBox>
      </div>
      {pointsNote && <Text className='mt-20'>{pointsNote}</Text>}
      {actions?.length > 0 && (
        <>
          <Box style={{ height: '100%' }} margin='15px 0 0 0'>
            <CustomFlexHeader align='center' wrap='nowrap'>
              <Col width={80} padding={0}>
                <Flex>
                  <Col width={50}>
                    <Text size={18} bold primary align='left' style={{ paddingLeft: '8px' }}>
                      Data
                    </Text>
                  </Col>
                  <Col width={50} style={{ paddingLeft: '0' }}>
                    <Text size={18} bold primary align='left'>
                      Categorie
                    </Text>
                  </Col>
                </Flex>
              </Col>
              <Col width={20} padding={0}>
                <Text size={18} bold primary align='right' style={{ paddingRight: '20px' }}>
                  Punti
                </Text>
              </Col>
            </CustomFlexHeader>
            <ReactShadowScroll
              style={{ width: '100%', maxHeight: '370px' }}
              isShadow={false}
              scrollColor={'#cccccc'}
              scrollColorHover='gray'
              styleSubcontainer={{ overflowX: 'hidden' }}
            >
              {actions?.map(({ id, details, points, category, created_at }) => (
                <Coin key={id} num={points} text={details} category={details} date={created_at} />
              ))}
            </ReactShadowScroll>
          </Box>
        </>
      )}
      {/* Replace this entire ExcelFile component with the button below */}
      {actions?.length > 0 && (
        <DownloadButton onClick={exportToExcel}>
          <span>DETTAGLIO</span> <IoMdDownload />
        </DownloadButton>
      )}
    </>
  );
};
export default Points;
