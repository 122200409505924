import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import styled from 'styled-components';
import { signinform } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import * as actionCreators from '../../store/actions';
import { showAuthForm } from '../../store/actions/auth';
import { getSlug } from '../../utils/utils';
import AuthContentWrapper from '../AuthContentWrapper/AuthContentWrapper';
import ErrorInModal from '../ErrorInModal';
import { MoreOauths } from '../Oauths';
import { Button, Flex, Input, Modal, Text } from '../Ui';

const Btns = styled.div`
  margin-top: -20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div`
  label {
    color: ${({ theme }) => theme.colore_testo_box} !important;
  }

  label[for='recordami'] {
    margin-left: 45px;
  }
`;
const CustomButton = styled(Button)`
  margin-top: 0 !important;
  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SigninForm = props => {
  const {
    inModal,
    error,
    clearError,
    signinInit,
    loading,
    redirect,
    whereToGo,
    fields,
    oauths,
    oauthInfo,
    isOauth,
    oauthSendToken,
    visible,
    individual_agreement,
    username,
    code: appCode,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const individual_agreement_accepted = useSelector(
    state => state.user?.user?.userinfo?.individual_agreement_accepted,
  );
  let objFields = {};
  fields.forEach(item => {
    objFields[item.name] = item;
  });
  const inputRef = useRef();
  const lastLocation = useLastLocation();
  const [t] = useTranslation();
  const signInForm = signinform(
    t('auth.email'),
    t('auth.password'),
    inputRef,
    inModal,
    objFields,
    appCode,
  );
  t();
  const dispatch = useDispatch();
  useEffect(() => {
    inputRef.current && inputRef.current.focus();
    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    if (error) {
      setShowModal(true);
      dispatch(showAuthForm(true));
    }

    // return () => {};
  }, [error]);

  const login = () => {
    localStorage.setItem('exp', formData.recordami.value);
    let usernameType = null;
    switch (username) {
      case 'email':
        usernameType = formData.email.value;
        break;
      case 'vatCode':
        usernameType = formData.vatcode.value;
        break;
      case 'external_id':
        usernameType = formData.external_id.value;
        break;
      default:
        break;
    }
    signinInit(usernameType, formData.password.value).then(() => {
      error ? dispatch(showAuthForm(true)) : dispatch(showAuthForm(false));
      dispatch(actionCreators.getViewer());
    });
  };

  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const { inputChangedHandler, formData, formSubmitHandler, firstSubmit } = useForm(
    login,
    signInForm,
  );

  //
  if (username === 'vatCode') {
    delete formData.email;
    delete formData.external_id;
  } else if (username === 'email') {
    delete formData.vatcode;
    delete formData.external_id;
  } else if (username === 'external_id') {
    delete formData.email;
    delete formData.vatcode;
  }

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        {...inp}
      />
    );
  });

  let oauthsContent = null;
  if (oauths !== undefined) {
    if (oauths.length > 0) {
      oauthsContent = (
        <MoreOauths
          oauths={oauths}
          oauthInfo={oauthInfo}
          inModal={inModal}
          whereToGo={whereToGo}
          isOauth={isOauth}
          oauthSendToken={oauthSendToken}
          lastLocation={lastLocation}
          redirect={redirect}
        />
      );
    }
  }
  console.log({ redirect, whereToGo, lastLocation, individual_agreement_accepted });
  if (redirect) {
    let to = whereToGo;
    if (lastLocation !== null) {
      if (
        lastLocation.pathname.split('/')[1] === 'activate' ||
        lastLocation.pathname.split('/')[1] === 'reset-password' ||
        lastLocation.pathname.split('/')[1] === 'auth'
      ) {
        to = '/';
      } else {
        to = lastLocation.pathname;
      }
    }
    if (inModal) {
      to = `/${getSlug()}`;
    }
    return <Redirect to={!individual_agreement_accepted ? '/single-agreement' : to} />;
  } else {
    return (
      <Container>
        <form onSubmit={formSubmitHandler}>
          <AuthContentWrapper title='Benvenuto. Accedi con le tue credenziali o registrati.'>
            {inputs}
            <Flex
              justify='space-between'
              direction='column'
              gap='20px'
              style={{ marginTop: '-20px', marginLeft: '45px' }}
            >
              <Button
                white
                secondary
                upper
                bold
                type='submit'
                loading={loading}
                width='48%'
                style={{ justifyContent: 'center' }}
              >
                Accedi
              </Button>
              <Text bold primary size={18} color='#000'>
                Non ti sei ancora registrato?
                <br />
                Clicca qui sotto per effettuare la registrazione!
              </Text>
              {visible && (
                <CustomButton active upper bold type='button' width='48%'>
                  <Link to='/auth/register'>Registrati</Link>
                </CustomButton>
              )}
            </Flex>
          </AuthContentWrapper>
        </form>
        <Flex style={{ marginLeft: '45px' }}>
          <Text style={{ marginRight: 5 }} text_box size={17}>
            {t('auth.forgotpswlabel')}
          </Text>
          <Link to='/auth/recovery-password'>
            <Text bold style={{ textDecoration: 'underline' }} text_link size={17}>
              {t('auth.presshere')}
            </Text>
          </Link>
        </Flex>

        <Modal alert show={showModal} close={modalHandler} type='error'>
          <ErrorInModal error={error} />
        </Modal>
      </Container>
    );
  }
};

SigninForm.defaultProps = {
  inModal: false,
};

SigninForm.propTypes = {
  inModal: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.number,
  redirect: PropTypes.bool,
  whereToGo: PropTypes.string,
  clearError: PropTypes.func,
  signinInit: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    redirect: state.auth.redirect,
    error: state.auth.errorsignin,
    loading: state.auth.loadingsignin,
    oauths: state.app.config.oauths,
    username: state.app.config.ownlogin.username,
    visible: state.app.config.visible,
    individual_agreement: state.app.config.individual_agreement,
    oauthInfo: state.app.oauthInfo,
    isOauth: state.auth.isOauth,
    fields: state.app.config.ownlogin.fields,
    code: state.app.config.code,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signinInit: (email, password, recordami) =>
      dispatch(actionCreators.signinInit(email, password, recordami)),
    clearError: () => dispatch(actionCreators.signinClearError()),
    oauthSendToken: (code, connect, data) =>
      dispatch(actionCreators.oauthSendToken(code, connect, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
